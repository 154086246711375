<template>
  <div class="home">
    <section class="main-container">
      <div class="headline-wrapper">
        <div class="headliner">Blog</div>
        <div class="bio-text">Code, Business, Design, Lifestyle</div>
      </div>
      <!-- Blog Content -->
      <div class="blog-wrapper">
        <div
          v-for="item in blogs"
          :key="item.id"
          class="card-container"
          @click="openDetails(item)"
        >
          <img :src="item.thumbnail" alt="brain" class="the-card" />
          <div class="titles">{{ item.title }}</div>
          <div class="date">{{ item.date }}</div>
          <!--<div class="card-overlay"></div> -->
        </div>
      </div>
      <!--Image overlay - underlay -->
      <div class="container">
        <div class="the-brain"></div>
        <div class="brain-overlay"></div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import { BIO } from '@/configs/static_text.js';
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'Home',
  computed: { ...mapGetters(['blogs']) },
  data() {
    return {
      brain: require('../assets/brain.png'),
      bio: BIO,
    };
  },
  created() {
    this.$store.dispatch('getBlogs');
  },
  methods: {
    openDetails(item) {
      this.$router.push(`/post/details/blogs/${item.id}`);
    },
    formatDate(date) {
      return moment(date).format('MMMM Do YYYY');
    },
  },
};
</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&display=swap');
* {
  font-family: 'Cardo', serif;
}
.main-container {
  position: relative;
  min-height: 100vh;
  margin-left: 10%;
  font-family: 'Cardo', serif;
  overflow-x: hidden;

  .headline-wrapper {
    position: relative;
    margin-top: 100px;
    z-index: 999;

    .headliner {
      font-size: 3rem;
      color: #fff;
      font-weight: bold;
      text-align: left;
      text-decoration: underline;
    }
  }

  .blog-wrapper {
    position: relative;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    margin-top: 50px;
    z-index: 999;
  }

  .added-marg {
    margin-top: 100px;
  }

  //card-container
  .card-container {
    position: relative;
    max-width: 350px;
    margin-right: 50px;
    margin-top: 25px;
  }

  .card-container:hover {
    cursor: pointer;
  }

  .card-container:nth-child(n + 4) {
    margin-top: 100px;
  }

  /* Make the image to responsive */
  .the-card {
    display: block;
    width: 100%;
    max-height: 300px;
    height: 250px;
  }

  .titles {
    font-size: 1.5rem;
    font-weight: 700;
    text-align: left;
    color: #fff;
    margin-top: 15px;
  }

  .date {
    font-size: 1rem;
    text-align: left;
    color: #8d8d8d;
  }

  /* The overlay effect - lays on top of the container and over the image */
  .card-overlay {
    position: absolute;
    margin-left: 10px;
    //   bottom: 0;
    //   top: 0;
    //   left: 0;
    //   right: 0;
    background-color: orange;
    //   opacity: 0.85;
  }

  //image css here
  .container {
    position: absolute;
    top: 10%;
    left: 40%;
    max-width: 600px;
    height: 500px;
    width: 400px;
  }

  /* Make the image to responsive */
  .the-brain {
    display: block;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0.85;
  }

  /* The overlay effect - lays on top of the container and over the image */
  .brain-overlay {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    background-color: black;
    opacity: 0.85;
  }
  .bio-text {
    font-size: 1.5rem;
    color: #fff;
    opacity: 0.85;
    font-weight: bold;
    text-align: left;
    margin-top: 20px;
  }
}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
  .main-container {
    position: relative;
    min-height: 100vh;
    margin-left: 0%;
    max-width: 100%;
    overflow-x: hidden;
    z-index: 998;
  }

  .headline-wrapper {
    position: relative;
    margin-top: 100px;
    z-index: 999;
    padding: 10px;
  }

  .headliner {
    font-size: 2rem;
    color: #fff;
    font-weight: bold;
    text-align: left;
    text-decoration: underline;
  }

  .blog-wrapper {
    position: relative;
    // flex-direction: column;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    margin-bottom: 50px;
    z-index: 999;
  }

  .bio-text {
    font-size: 1.25rem;
    color: #fff;
    opacity: 0.85;
    font-weight: bold;
    text-align: left;
    padding: 5px;
    margin-top: 20px;
  }

  .card-container {
    position: relative;
    max-width: 90%;
    width: 90%;
    margin-right: unset;
    margin-top: 5px;
    display: flex;
    flex-direction: column;
  }
  //image css here
  .container {
    position: absolute;
    top: 20%;
    left: 0;
    max-width: 250px;
    height: 400px;
  }

  /* Make the image to responsive */
  .the-brain {
    display: block;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0.85;
  }

  /* The overlay effect - lays on top of the container and over the image */
  .brain-overlay {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    background-color: black;
    opacity: 0.85;
  }

  .overscript {
    width: 50px;
    font-size: 2rem;
    font-weight: 700;
    color: #fff;
  }

  // .card-container:nth-child(n + 3) {
  //   margin-top: 75px;
  // }

  /* Make the image to responsive */
  .the-card {
    display: block;
    // max-height: 300px;
    margin-top: 40px;
    width: 100%;
  }

  .titles {
    font-size: 1.5rem;
    font-weight: 700;
    text-align: left;
    color: #fff;
    margin-top: 15px;
  }

  .date {
    font-size: 1rem;
    text-align: left;
    color: #8d8d8d;
  }
}

/* Portrait */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
}

/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
}
</style>
